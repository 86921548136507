import { Button } from "../../components";
import { useAppDispatch } from "../../redux";
import { openConnectWalletModal } from "../../redux/features/uiSlice";

type ConnectWalletButtonProps = {
  small?: boolean;
  moreRadius?: boolean;
  width?: string;
  height?: string;
  shortVariant?: boolean;
};

export const ConnectWalletButton = ({
  small,
  moreRadius,
  width,
  height,
  shortVariant,
}: ConnectWalletButtonProps) => {
  const dispatch = useAppDispatch();
  return (
    <Button.Primary
      type="button"
      style={{ minWidth: width, height }}
      bigSize={!small}
      moreRadius={moreRadius}
      onClick={() => dispatch(openConnectWalletModal())}
    >
      {shortVariant ? "Connect" : "Connect Wallet"}
    </Button.Primary>
  );
};
