import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSwitchChain } from "wagmi";
import { api } from "../../../../api";
import {
  usePaginationQueryParams,
  useUsdcTokenAddress,
} from "../../../../hooks";
import { usePoolsV2ContractAddress } from "../../../../hooks/usePoolsV2ContractAddress";
import { actions, useAppDispatch, useAppSelector } from "../../../../redux";
import {
  invalidatePoolData,
  validatePoolData,
} from "../../../../redux/features/uiSlice";
import { toast } from "../../../../toasts";
import { getErrorMessage } from "../../../../utils";
import RpcProviderManager from "../../../../utils/jsonRpcProvider";
import {
  useEarnContextState,
  useEarnDispatchContext,
} from "./EarnContextProvider";
import { EarnAction, SortOrderType } from "./earnReducer";
import { useTokenData } from "../../../../hooks/useTokenData";

export const useEarnHooks = () => {
  const earnState = useEarnContextState();
  const earnDispatch = useEarnDispatchContext();
  const chainId = useAppSelector((state) => state.ui.chainId);
  const { isSuccess: isSuccessSwitchNetwork } = useSwitchChain();

  const dispatch = useAppDispatch();
  const { walletAddress } = useAppSelector((state) => state.user.userData);
  const { isLogged, loading } = useAppSelector((store) => store.user);
  const { page, itemsPerPage } = usePaginationQueryParams();
  const [currentToken, setCurrentToken] = useState("USDC");
  const poolsV2ContractAddress = usePoolsV2ContractAddress();
  const provider = RpcProviderManager.getInstance().getProvider(chainId);
  const usdcTokenAddress = useUsdcTokenAddress();
  const { decimals: usdcTokenDecimals } = useTokenData("USDC");

  const fetchV2Pools = async (isRefetch = false) => {
    if (isRefetch) {
      earnDispatch({ type: EarnAction.REFETCH_START });
    } else {
      earnDispatch({ type: EarnAction.FETCH_POOLS_V2_START });
    }

    const currentTime = dayjs();
    try {
      if (isLogged) {
        const response = await api.pools.getLevelsAsLogged({
          page,
          limit: itemsPerPage,
          sortBy: [
            `createdAt:${
              earnState.sortOrder === SortOrderType.TIME_TO_ENTER
                ? SortOrderType.DESC
                : earnState.sortOrder
            }`,
            `pool.poolSettings.endOfDeposits:${
              earnState.sortOrder === SortOrderType.TIME_TO_ENTER
                ? SortOrderType.DESC
                : earnState.sortOrder
            }`,
            "level:DESC",
          ],
          filter: {
            "pool.start": `$lte:${new Date().toISOString()}`,
            "pool.contract.chain.chainId": `$eq:${chainId}`,
            "pool.version": `$eq:v2`,
          },
        });

        const shortPoolsData = response.data.data.filter((item) => {
          try {
            return item.pool.isShort;
          } catch (error) {
            return false;
          }
        });

        const longPoolsData = response.data.data.filter((item) => {
          try {
            return (
              !item.pool.isShort && dayjs(item.pool.end).isAfter(currentTime)
            );
          } catch (error) {
            return false;
          }
        });

        const highestShortAPRPool = shortPoolsData.reduce<PoolLevel | null>(
          (highest, current) =>
            current.usdcApr > (highest?.usdcApr || 0) ? current : highest,
          null
        );

        const highestLongAPRPool = longPoolsData.reduce<PoolLevel | null>(
          (highest, current) =>
            current.usdcApr > (highest?.usdcApr || 0) ? current : highest,
          null
        );

        dispatch(
          actions.pool.setHighestShortAPR(highestShortAPRPool?.usdcApr || "0")
        );
        dispatch(
          actions.pool.setHighestLongAPR(highestLongAPRPool?.usdcApr || "0")
        );

        const endedRoundsData = response.data.data.filter((item) => {
          try {
            return dayjs(item.pool.end).isBefore(currentTime);
          } catch (error) {
            return false;
          }
        });

        const longPoolsDataOnlyForCurrentToken = longPoolsData.filter(
          (pool) => pool.pool.token === currentToken // TODO We need filter by token on API side
        );

        const shortPoolsDataOnlyForCurrentToken = shortPoolsData.filter(
          (pool) => pool.pool.token === currentToken
        );

        const endedRoundsDataOnlyForCurrentToken = endedRoundsData.filter(
          (pool) => pool.pool.token === currentToken
        );

        const sortedLongPoolsDataByDepositAvailable =
          longPoolsDataOnlyForCurrentToken.sort((a, b) => {
            const aHasReachedHardCap =
              Number(
                a.poolLevelBalances.find((el) => el.token === "USDC")?.balance
              ) === Number(a.pool.hardCap);

            const bHasReachedHardCap =
              Number(
                b.poolLevelBalances.find((el) => el.token === "USDC")?.balance
              ) === Number(b.pool.hardCap);

            if (!aHasReachedHardCap && bHasReachedHardCap) return -1;
            if (aHasReachedHardCap && !bHasReachedHardCap) return 1;

            return 0;
          });

        const sortedLongPoolsDataByWhitelistAvailable = [
          ...sortedLongPoolsDataByDepositAvailable,
        ].sort((a, b) => {
          const aIsWhitelistPool = a.isWhitelisted;
          const bIsWhitelistPool = b.isWhitelisted;

          const aHasWhitelistButUserNotOnIt =
            aIsWhitelistPool && !a.isUserOnTheWhitelist;
          const bHasWhitelistButUserNotOnIt =
            bIsWhitelistPool && !b.isUserOnTheWhitelist;

          if (aHasWhitelistButUserNotOnIt && !bHasWhitelistButUserNotOnIt)
            return 1;

          if (!aHasWhitelistButUserNotOnIt && bHasWhitelistButUserNotOnIt)
            return -1;

          return 0;
        });

        earnDispatch({
          type: EarnAction.FETCH_POOLS_V2_SUCCESS,
          payload: {
            poolsLongV2: sortedLongPoolsDataByWhitelistAvailable,
            poolsShort: shortPoolsDataOnlyForCurrentToken,
            endedPools: endedRoundsDataOnlyForCurrentToken,
            pageCount: response.data.meta.totalPages,
          },
        });
        dispatch(validatePoolData());
      } else {
        const response = await api.pools.getLevels({
          page,
          limit: itemsPerPage,
          sortBy: [
            `createdAt:${
              earnState.sortOrder === SortOrderType.TIME_TO_ENTER
                ? SortOrderType.DESC
                : earnState.sortOrder
            }`,
            "level:DESC",
          ],
          filter: {
            "pool.start": `$lte:${new Date().toISOString()}`,
            "pool.contract.chain.chainId": `$eq:${chainId}`,
            "pool.version": `$eq:v2`,
          },
        });

        const shortPoolsData = response.data.data.filter((item) => {
          try {
            return item.pool.isShort;
          } catch (error) {
            return false;
          }
        });

        const longPoolsData = response.data.data.filter((item) => {
          try {
            return (
              !item.pool.isShort && dayjs(item.pool.end).isAfter(currentTime)
            );
          } catch (error) {
            return false;
          }
        });

        const endedRoundsData = response.data.data.filter((item) => {
          try {
            return dayjs(item.pool.end).isBefore(currentTime);
          } catch (error) {
            return false;
          }
        });

        const longPoolsDataOnlyForCurrentToken = longPoolsData.filter(
          (pool) => pool.pool.token === currentToken
        );

        const shortPoolsDataOnlyForCurrentToken = shortPoolsData.filter(
          (pool) => pool.pool.token === currentToken
        );

        const endedRoundsDataOnlyForCurrentToken = endedRoundsData.filter(
          (pool) => pool.pool.token === currentToken
        );

        earnDispatch({
          type: EarnAction.FETCH_POOLS_V2_SUCCESS,
          payload: {
            poolsLongV2: longPoolsDataOnlyForCurrentToken,
            poolsShort: shortPoolsDataOnlyForCurrentToken,
            endedPools: endedRoundsDataOnlyForCurrentToken,
            pageCount: response.data.meta.totalPages,
          },
        });
      }
    } catch (e: unknown) {
      toast.errorHandler(e, "Fetching pools failed!");
      earnDispatch({
        type: EarnAction.FETCH_POOLS_V2_FAILURE,
        payload: getErrorMessage(e, "Fetching pools failed!"),
      });
    }
  };

  const fetchV1Pools = async () => {
    earnDispatch({ type: EarnAction.FETCH_POOLS_V1_START });

    try {
      const endpoint = isLogged
        ? api.pools.getLevelsAsLogged
        : api.pools.getLevels;

      const response = await endpoint({
        page,
        limit: itemsPerPage,
        sortBy: ["createdAt:DESC", "level:DESC"],
        filter: {
          "pool.start": `$lte:${new Date().toISOString()}`,
          "pool.contract.chain.chainId": `$eq:${chainId}`,
          "pool.version": `$eq:v1`,
        },
      });

      const longPoolsData = response.data.data.filter((item) => {
        try {
          return !item.pool.isShort;
        } catch (error) {
          return false;
        }
      });

      const endedRoundsData = response.data.data.filter((item) => {
        try {
          return dayjs(item.pool.end).isBefore(dayjs());
        } catch (error) {
          return false;
        }
      });

      const longPoolsDataOnlyForCurrentToken = longPoolsData.filter(
        (pool) => pool.pool.token === currentToken
      );

      const endedRoundsDataOnlyForCurrentToken = endedRoundsData.filter(
        (pool) => pool.pool.token === currentToken
      );

      earnDispatch({
        type: EarnAction.FETCH_POOLS_V1_SUCCESS,
        payload: {
          poolsLongV1: longPoolsDataOnlyForCurrentToken,
          endedPools: endedRoundsDataOnlyForCurrentToken,
          pageCount: response.data.meta.totalPages,
        },
      });
    } catch (e: unknown) {
      toast.errorHandler(e, "Fetching pools failed!");
      earnDispatch({
        type: EarnAction.FETCH_POOLS_V1_FAILURE,
        payload: getErrorMessage(e, "Fetching pools failed!"),
      });
    }
  };

  useEffect(() => {
    if (isSuccessSwitchNetwork) {
      dispatch(invalidatePoolData());
    }
  }, [isSuccessSwitchNetwork]);

  useEffect(() => {
    dispatch(actions.pool.getPoolsContractFee(usdcTokenDecimals));
    dispatch(
      actions.pool.getPoolsContractFeeV2({
        poolsContractAddress: poolsV2ContractAddress,
        provider,
        token: usdcTokenAddress,
        decimals: usdcTokenDecimals,
      })
    );
    if (isLogged && !loading) {
      dispatch(actions.staking.getStakingData());
      dispatch(actions.staking.getLockoutTime());
    }
  }, [isLogged, loading]);

  const refreshPools = (refresh = false) => {
    if (!chainId) return;
    fetchV2Pools(refresh);
    fetchV1Pools();
    dispatch(
      actions.wallet.getBalances({
        address: walletAddress,
        chainId,
      })
    );
  };

  const toggleSortOrder = () => {
    if (earnState.sortOrder === SortOrderType.TIME_TO_ENTER) {
      earnDispatch({
        type: EarnAction.SORT_ORDER_CHANGE,
        payload: SortOrderType.ASC,
      });
    } else if (earnState.sortOrder === SortOrderType.ASC) {
      earnDispatch({
        type: EarnAction.SORT_ORDER_CHANGE,
        payload: SortOrderType.DESC,
      });
    } else if (earnState.sortOrder === SortOrderType.DESC) {
      earnDispatch({
        type: EarnAction.SORT_ORDER_CHANGE,
        payload: SortOrderType.TIME_TO_ENTER,
      });
    }
  };

  const toggleToken = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentToken(e.target.value);
  };

  return {
    refreshPools,
    toggleSortOrder,
    toggleToken,
  };
};
