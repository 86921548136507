import { useCallback, useEffect, useRef, useState } from "react";
import { useAccount } from "wagmi";
import { initKyc, redirectAfterKyc } from "../../../api/kyc";
import { useProtocolSettingsAddress } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { getUserData } from "../../../redux/features/userSlice";
import { toast } from "../../../toasts";

interface UseKycParams {
  handleModalClose?: () => void;
  handleModalOpen?: () => void;
  onboardingVariant?: boolean;
  notMountListener?: boolean;
}

export const useKYC = ({
  handleModalClose,
  handleModalOpen,
  onboardingVariant,
  notMountListener,
}: UseKycParams = {}) => {
  const [inProgressInit, setInProgressInit] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const { isLogged } = useAppSelector((store) => store.user);
  const { chainId } = useAccount();
  const protocolSettingsAddress = useProtocolSettingsAddress();
  const dispatch = useAppDispatch();

  const isListenerMountedRef = useRef(false);
  const isRequestPendingRef = useRef(false);
  const lastRequestTimeRef = useRef(0);

  const handleInitKyc = async () => {
    if (inProgressInit) return;
    setInProgressInit(true);
    try {
      const { data } = await initKyc();
      const { message: url } = data;
      setIframeSrc(url);
    } catch (e: unknown) {
      toast.errorHandler(e, "error");
    } finally {
      setInProgressInit(false);
    }
  };

  const handleRedirectAfterKyc = useCallback(
    async (authCode: string, kycState: string) => {
      const now = Date.now();
      if (
        !chainId ||
        isRequestPendingRef.current ||
        now - lastRequestTimeRef.current < 100
      )
        return;

      isRequestPendingRef.current = true;
      lastRequestTimeRef.current = now;

      try {
        await redirectAfterKyc(authCode, kycState);
        if (isLogged) {
          const getUserDataPayload = {
            chainId,
            protocolSettingsAddress,
          };
          await dispatch(getUserData(getUserDataPayload)).unwrap();
          if (handleModalClose) {
            handleModalClose();
          }
        }
      } catch (e: unknown) {
        toast.errorHandler(e, "error");
        setInProgressInit(false);
      } finally {
        isRequestPendingRef.current = false;
        if (handleModalClose) handleModalClose();
      }
    },
    [chainId, isLogged, protocolSettingsAddress]
  );

  const messageReceiver = useCallback(
    // eslint-disable-next-line
    async (receivedMessage: any) => {
      if (receivedMessage.origin === "https://app.fractal.id") {
        if (receivedMessage.data.error) {
          toast.errorHandler(receivedMessage.data.error, "error");
          if (handleModalClose) handleModalClose();
        } else if (receivedMessage.data) {
          await handleRedirectAfterKyc(
            receivedMessage.data.code,
            receivedMessage.data.state
          );
        }
      }
    },
    [handleRedirectAfterKyc]
  );

  useEffect(() => {
    if (iframeSrc && !onboardingVariant) {
      if (handleModalOpen) handleModalOpen();
    }
  }, [iframeSrc, onboardingVariant]);

  useEffect(() => {
    if (!notMountListener) {
      window.addEventListener("message", messageReceiver);
      isListenerMountedRef.current = true;
    }

    return () => {
      if (!notMountListener) {
        window.removeEventListener("message", messageReceiver);
        isListenerMountedRef.current = false;
      }
    };
  }, []);

  return {
    handleInitKyc,
    iframeSrc,
    inProgressInit,
  };
};
